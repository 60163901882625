import React, { useEffect, useState, useCallback } from "react";
import { loadFormWithAnswer, createAnswer } from "helpers/form";
// import CreateForm from 'modules/CreateForm';
import { LoadingBox } from "components/LoadingIndicators";
import CompactForm from "modules/CreateForm/CompactForm";
import SettingsExpandableFormSummary from "@hubins/components/SettingsExpandableFormSummary";
import { useNotificationContext } from "@hubins/components/NotificationContext/NotificationContext";
import { formatDate } from "helpers";
import { __ } from 'localisation';

const SectionForm = ({
  form,
  profileUuid,
  portfolioUuid,
  uuid,
  role,
  setSubmittedDueDiligence,
  submittedDueDiligence,
  isExecuted,
}) => {
  const [formContent, setFormContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submittedAt, setSubmittedAt] = useState(false);

  const { setNotification } = useNotificationContext();

  useEffect(() => {
    if (form) {
      loadFormWithAnswer(
        form,
        "approval",
        profileUuid,
        portfolioUuid,
        setFormContent,
        false,
        isExecuted
          ? {
            approvalSectionUuid: uuid,
          }
          : {},
      );
    }
  }, [form]);

  useEffect(() => {
    if (formContent && uuid) {
      if (
        formContent.submitted_at &&
        formContent.approval_section_uuid === uuid
        && !submittedDueDiligence.includes(form)
      ) {
        setSubmittedAt(formContent.submitted_at);
        setSubmittedDueDiligence([...submittedDueDiligence, form]);
      }
    }
  }, [formContent, uuid, submittedDueDiligence, form]);

  const submit = useCallback(async (values) => {
    setLoading(true);
    try {
      const body = {
        ...formContent,
        data: values,
        profileUuid,
        portfolioUuid,
        approvalSectionUuid: uuid,
      };

      await createAnswer(body, setError);
      setNotification({
        type: "success",
        message: <>Due diligence form has been saved </>,
      });
      setSubmittedDueDiligence((prevSubmittedDueDiligence) => [...prevSubmittedDueDiligence, form]);
      setSubmittedAt(formatDate(new Date, true)); // TODO: make this in some helper
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [formContent, profileUuid, portfolioUuid, uuid, submittedDueDiligence, form, setNotification, setSubmittedDueDiligence, setLoading]);

  return (
    <>
      {form && (
        <>
          {formContent ? (
            <>
              {role.can_edit ? (
                <CompactForm
                  formData={formContent.content}
                  submitType={submit}
                  submitText="Spara svar"
                  loading={loading}
                  disabled={loading}
                  error={error}
                  className="s-top-xl neutral-1 s-bottom-xl box-padding"
                  submittedAt={submittedAt}
                />
              ) : (
                <SettingsExpandableFormSummary
                  className="s-top-xl"
                  summary={formContent}
                  titleSize="5"
                  title={__(`dd_${formContent.key}`)}
                />
              )}
            </>
          ) : (
            <LoadingBox />
          )}
        </>
      )}
    </>
  );
};

export default SectionForm;
