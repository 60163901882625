import React, { useState, useEffect, useCallback } from 'react';
import __ from 'localisation';
import { LoadingBox } from 'components/LoadingIndicators';
import middleman from 'helpers/middleman';
import ProgressTab from "components/ProgressTab";
import FixedFooter from "components/FixedFooter";
import { TabList, Tabs, TabPanel, Tab } from "react-tabs";
import  Section  from './Section';
import Verdicts from './Verdicts';
import ApprovalProgress from 'components/ApprovalProgress';
import { connect } from "react-redux";
import { getBackofficeRole } from 'helpers/userRoles';
import { useNotificationContext } from '@hubins/components/NotificationContext/NotificationContext';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import { triggerScrollToTop } from "helpers";
import InvalidModal from './InvalidModal';
import './index.scss';

const NewApproval = ({ match, roles, userUuid }) => {

  const { sourceUuid } = match.params;
  const [approval, setApproval] = useState(null);
  const [selected, setSelected] = useState(0);
  const [verdictSubmitLoading, setVerdictSubmitLoading] = useState(false);
  const [submittedDueDiligence, setSubmittedDueDiligence] = useState([]);
  const [isExecuted, setIsExecuted] = useState(false);
  const [role, setRole] = useState(null);
  const [invalid, setInvalid] = useState([]);
  const [showValidatonModal, setShowValidationModal] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const [priority, setPriority] = useState(null);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [endDate, setEndDate] = useState(undefined);
  const [message, setMessage] = useState('');

  const { setNotification } = useNotificationContext();

  const fetchApproval = async () => {
    const res = await middleman.get(`/ql/crm/approvals/${sourceUuid}`);
    const myBackofficeRole = getBackofficeRole(roles);
    const myRole = res.data.roles.find(r => r.name === myBackofficeRole);
    // order by created_at
    res.data.section = res?.data?.sections?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)) || res?.data?.sections;
    setApproval(res.data);
    setEndDate(res.data?.completed_at);
    setDueDate(res.data.deadline);
    setPriority(res.data.priority);
    setApprovalStatus(res.data.status);
    const executed = res.data.actions.every(a => a.status === 'executed');
    setIsExecuted(executed);
    if (myRole) {
      if (executed && myRole) {
        myRole.can_edit = false;
      }
      setRole(myRole);
    } else {
      setRole({ can_edit: false });
    }


  };

  const handleInvalid = (isInvalid, key, msg) => {
    if (isInvalid) {
      setInvalid([...invalid.filter(i => i.key !== key), { key, msg }]);
    } else {
      setInvalid(invalid.filter(i => i.key !== key));
    }
  };

  const archive = async (shouldArchive) => {
    if (!shouldArchive || window.confirm('Are you sure you want to archive this approval?')) {
      setArchiveLoading(true);
      try {
        const result = await middleman.post(`/ql/crm/approvals/update/${approval.uuid}`, { status: shouldArchive ? 'archived' : 'active' });
        setApproval(result.data);
        setApprovalStatus(result.data.status);
        setNotification({ type: 'success', message: shouldArchive ? <>Approval has been archived</> : <>Approval has been unarchived</> });
      } catch (e) {
        console.error(e);
      }
      setArchiveLoading(false);
    }
  };

  const submitVerdict = async (status, files) => {
    setVerdictSubmitLoading(true);
    try {

      if (status === 'approved' && role.can_edit) {
        if (invalid.length > 0) {
          setShowValidationModal(true);
          setVerdictSubmitLoading(false);
          return;
        }
        const availableForms = approval.sections.filter(s => s.form).reduce((carry, s) => {
          const splittedForms = s.form.split(',');
          return [...carry, ...splittedForms];
        }, []);
        const thereIsUnsubmittedForm = availableForms.some(f => !submittedDueDiligence.includes(f));
        if (thereIsUnsubmittedForm) {
          setNotification({ type: 'error', message: <div className="center"><Icon icon="Sign Warning" size={24} /> One or more Due diligence forms has not been submitted. Please make sure u submit all Due diligence forms before you leave a verdict.</div> });
          setVerdictSubmitLoading(false);
          return;
        }
      }

      if (files && files.length > 0) {
        const filePromises = files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          return middleman.post(`/ql/crm/approvals/upload/${approval.uuid}`, formData);
        });
        await Promise.all(filePromises);
      }

      const updateBody = {
        verdict: {
          status,
          message,
        },
        deadline: (dueDate && role.can_edit) ? dueDate : undefined,
        priority: (priority && role.can_edit) ? priority : undefined,
      };

      const result = await middleman.post(`/ql/crm/approvals/update/${approval.uuid}`, updateBody);
      setMessage('');
      setNotification({ type: 'success', message: <>Verdict has been saved</> });
      setApproval(result.data);
    } catch (e) {
      console.error(e);
    }
    setVerdictSubmitLoading(false);
  };

  useEffect(() => {
    fetchApproval();
  }, []);

  const next = useCallback(() => {
    if (selected !== approval.sections.length - 1) {
      setSelected(selected + 1);
      triggerScrollToTop(false);
    }
  }, [selected, approval]);

  const previous = useCallback(() => {
    if (selected !== 0) {
      setSelected(selected - 1);
      triggerScrollToTop(false);
    }
  }, [selected]);

  if (!approval || approval.sections.length < 1 || !role) return <LoadingBox />;


  return (
    <>
      <InvalidModal open={showValidatonModal} setOpen={setShowValidationModal} items={invalid} />
      <Tabs
        className="react-tabs flex flex-pusher"
        selectedTabClassName="tab--selected"
        forceRenderTabPanel
        onSelect={(newSelected) => {
          triggerScrollToTop(false);
          setSelected(newSelected);
        }}
        selectedIndex={selected}
      >
        <ButtonWrap>
          <Button className="s-bottom-xl transparent large" icon="arrow left 1" iconLeft href="/dashboard/approvals">{__('back')}</Button>
        </ButtonWrap>
        <ApprovalProgress roles={approval.roles} myRole={role} userUuid={userUuid} />
        {approval.sections.map((section, index) => (
          <TabPanel
            key={section.uuid}
            className={selected === index ? "" : "hidden"}
          >
            <Section
              role={role}
              profileUuid={approval.profile_uuid || section?.params?.profile_uuid}
              portfolioUuid={approval.portfolio_uuid || section?.params?.portfolio_uuid}
              setSubmittedDueDiligence={setSubmittedDueDiligence}
              submittedDueDiligence={submittedDueDiligence}
              isExecuted={isExecuted}
              setInvalid={handleInvalid}
              endDate={endDate}
              {...section}
            />
          </TabPanel>
        ))}
        <div>
          {(selected === approval.sections.length - 1) ? (
            <Verdicts
              role={role}
              canEdit={role.can_edit}
              verdicts={approval.verdicts}
              files={approval.files}
              submit={submitVerdict}
              loading={verdictSubmitLoading}
              dueDate={dueDate}
              setDueDate={setDueDate}
              priority={priority}
              setPriority={setPriority}
              isExecuted={isExecuted}
              archive={archive}
              archiveLoading={archiveLoading}
              approvalStatus={approvalStatus}
              message={message}
              setMessage={setMessage}
            />
          ) : (
            <Button className="cta" icon="arrow right 1" iconColor="white" size="large" onClick={next}>
              Continue
            </Button>
          )}
        </div>
        {approval.sections.length > 1 ? (
          <FixedFooter fill className="fixed">

            {/* <ProgressTabGroup
              // className="backoffice"
              // key={section.uuid}
            > */}
            <TabList className="progresstabs">
              {approval.sections.map((section, index) => (
                <>
                  {ProgressTab({
                    className: "progresstab",
                    index: index,
                    title: __(`approval_section_type_${section.type}`),
                    status: selected,
                    relativeWidth: (1 / approval.sections.length),
                    group: true,
                    error: index > selected,
                    disabled: false,
                  })}
                </>
              ))}
            </TabList>
            {/* </ProgressTabGroup> */}

            <Button icon="arrow left 1" onClick={previous} iconLeft className="s-right-m" disabled={selected === 0}>Previous</Button>
            <Button className="cta" icon="arrow right 1" iconColor="white" disabled={selected === (approval.sections.length - 1)} onClick={next}>Next</Button>
          </FixedFooter>
        ) : (
          <TabList className="hidden">
            <Tab>
              <></>
            </Tab>
          </TabList>
        ) }
      </Tabs>
    </>
  );

};

function mapStateToProps(state) {
  return {
    roles: state.auth.User.userRoles,
    userUuid: state.auth.User.uuid,
  };
}

export default connect(mapStateToProps)(NewApproval);
