import {
  useQuery,
} from 'react-query';


import middleman from 'helpers/middleman';

export const useActiveApprovalsForRole = (role, action, userUuid = undefined, page = false) =>
  useQuery(
    ['active-approvals', role, action, userUuid, page],
    () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, {
      status: 'active',
      roles: {
        name: role,
        status: 'active',
        user_uuid: userUuid ? userUuid : undefined,
      }, actions: action ? {
        type: action,
      } : undefined,
    }).then((res) => res.data), {
      enabled: !!role,
      refetchOnWindowFocus: false,
    });



export const useMyActiveApprovals = (userUuid, args, page = false) => useQuery(
  [
    'my-active-approvals',
    userUuid,
    args,
    page
  ],
  () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, {
    verdicts: {
      user_uuid: userUuid,
    },
    actions: {
      status: args.archived ? 'executed' : 'init',
      type: args.action ? args.action : undefined,
    },
  }).then((res) => res.data));

export const useMyArchivedApprovals = (userUuid, page = false) => useQuery(
  [
    'my-archived-approvals',
    userUuid,
    page
  ],
  () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, {
    verdicts: {
      user_uuid: userUuid,
    },
    actions: {
      status: 'executed',
    },
  }).then((res) => res.data), {
    refetchOnWindowFocus: false,
    enabled: !!userUuid,
  });

export const useFilteredApprovals = (args, page = false) => useQuery(
  [
    'filtered-approvals',
    args,
    page
  ],
  () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, args).then((res) => res.data), {
    refetchOnWindowFocus: false,
    enabled: !!args,
  });

export const useFollowUpApprovals = (role, page = false) => useQuery(
  [
    'follow-up-approvals',
    role,
    page
  ],
  () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, {
    verdicts: {
      role: role,
    },
    actions: {
      status: 'init',
    },
  }).then((res) => res.data), {
    refetchOnWindowFocus: false,
    enabled: !!role,
  });

export const useDeadlineHasPassedApprovals = (role, page = false) => useQuery(
  [
    'deadline-has-passed-approvals',
    role,
    page
  ],
  () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, {
    status: 'active',
    roles: {
      name: role,
      status: 'active',
    },
    actions: {
      status: 'init',
    },
    deadline: true,
  }).then((res) => res.data), {
    refetchOnWindowFocus: false,
    enabled: !!role,
  });

export const useProfilePortfolioApprovals = (
  profileUuid: string | undefined = undefined,
  portfolioUuid: string | undefined = undefined,
  status: string | undefined = undefined,
  page: number | undefined = undefined) => useQuery(
  [
    'profile-portfolio-approvals',
    profileUuid,
    portfolioUuid,
    page
  ],
  () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, {
    profile_uuid: profileUuid,
    portfolio_uuid: portfolioUuid,
    actions: status ? {
      status: 'executed',
    } : undefined,
  }).then((res) => res.data), {
    refetchOnWindowFocus: false,
    enabled: !!profileUuid || !!portfolioUuid,
  });

export const useCompletedApprovals = (action, page = false) => useQuery(
  [
    'completed-approvals',
    action,
  ],
  () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, {
    actions: {
      status: 'executed',
      type: action,
    },
  }).then((res) => res.data), {
    refetchOnWindowFocus: false,
  });

export const useArchivedApprovals = (action, page = false) => useQuery(
  [
    'archived-approvals',
    action,
    page
  ],
  () => middleman.post(`/ql/crm/approvals/filter${page ? `/${page}` : ''}`, {
    status: 'archived',
    actions: {
      type: action,
    },
  }).then((res) => res.data), {
    refetchOnWindowFocus: false,
  });
